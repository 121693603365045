$severe-flood-warning-colour: #C40000;
$flood-warning-colour: #C40000;
$flood-alert-colour: #EB7100;

$severe-flood-warning-text-colour: #C40000;
$flood-warning-text-colour: #C40000;
$flood-alert-text-colour: #cf4b09;

$open-flood-warning-count-border-colour: $borderlightgrey;

$health-check-banner-background: #FFFF00;