﻿.checkbox-container {
    margin-top: 10px;
}

.checkbox-container .checkbox-container__element input[type="checkbox"] {
    position: absolute;
}

.checkbox-container .checkbox-container__element input[type="checkbox"] + label {
    background-image: none;
    background-repeat: no-repeat;
    background-color: #007485;
    color: #ffffff;
    border-color: #007485;
    background-size: 18px 18px;
    background-position-y: center;
    background-position-x: 10px;
    padding: 1em;
    padding-left: 2.5em;
    cursor: pointer;
    display: inline-block;
    position: relative;
}

.checkbox-container .checkbox-container__element input[type="checkbox"]:focus + label {
    background-color: #FD0;
    color: #0B0C0C !important;
}

.checkbox-container .checkbox-container__element input[type="checkbox"]:checked + label {
    background-image: url(../images/actionicons/check-white.svg);
}

.checkbox-container .checkbox-container__element{
    height: 80px;
}