﻿
// Mixin to override default styling
// this is a temp solution until we define a style guide based on NRW.Core.Styling 
@mixin mobile {
    @media screen and (max-width: #{$mobileWidth + 20}) {
        @content;
    }
}

@mixin lt-desktop {
    @media screen and (max-width: #{$desktopWidth + 19}) {
        @content;
    }
}

@mixin lt-xldesktop {
    @media screen and (max-width: #{$xldesktopWidth + 19}) {
        @content;
    }
}