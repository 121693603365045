.further-information-section {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-left: 15px;
}

.further-information-header {
    color: #58595B;
    font-style: normal;
    font-weight: 900;
    font-size: to-rem(20px);
    margin-top: 40px;
}

.further-information-text {
    display: flex;
    font-size: to-rem(16px);
    color: $darkgrey;
}

.further-information-you-can-also {
    margin-top: 15px;
}

.further-information-contact-details {   
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    align-items: flex-start;
}

.further-information-phone {
    font-size: to-rem(16px);
    margin-top: 5px;
}   

.further-information-service-notice {
    margin-top: 5px;
}

.further-information-call-charges {
    font-size: to-rem(16px);
    margin-top: 5px;
}

.further-information {
    &-header, &-you-can-also {
        font-size: to-rem(30px);
        font-weight: 700;
        line-height: 34px;
        padding-top: 30px;
    }

    &-other {
        font-size: to-rem(24px);
        font-weight: 700;
        line-height: 28px;
        padding-top: 30px;
    }
}

.further-information-links-list {
    display: block;
    margin-left: 15px;
    margin-right: 24px;
}

.further-information-links-item{
    font-size: to-rem(20px);
    list-style-type: circle;
    list-style: outside;
    margin-top: 5px;
}

.further-information-links-item__link {
    text-decoration: none;
    font-size: to-rem(16px);
    color: #007485;
    text-align: center;
    font-weight: 700;
}

.further-information-elsewhere-links {
    display: flex;
    flex-direction: column;
    margin-top: 37px;
    background-color: #e9e9ea;
    padding: 30px;
    overflow: hidden;
    align-items: flex-start;
}

.further-information-elsewhere-links-header {
    font-size: to-rem(24px);
    font-weight: 700;
    color: #58595B;
    padding-bottom: 20px;
    line-height: 28px;
}

.further-information-elsewhere__link {
    display: inline-block;
    clear: both;
}

@include desktop {
    .further-information-section {
        margin-top: 53px;
    }  

    .further-information-header{
        font-size: to-rem(30px);
    }

    .further-information-contact-details {   
        flex-direction: column;
        color: $darkgrey;
    }

    .further-information-section {
        margin-left: 0px;
    }  

    .further-information-links-item{
        list-style: inside;
    }

    .further-information-elsewhere-links {
        margin-top: 47px;
    }

    .further-information-call-charges {
        font-size: to-rem(16px);
        color: #007485;
    }


}