.map-key-bar {
    padding: 0px;

    .accordion__title-bar {
        background-color: #fff;
    }

    &__title {
        padding-bottom: 0;
    }

    &__column {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    &__desktop-title-bar {
        display: none;
    }

    &__desktop-title {
        font-weight: bold;
    }

    &__mobile-title-bar {
        display: flex;
        flex-direction: row;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: left;
        align-items: center;
        cursor: pointer;
        color: inherit;
    }

    &__content-open {
        display: block;
    }

    &__mobile-title-icon {
        transition: .25s all linear;
        transform: rotate(0deg);
        height: 20px;
        width: 20px;
    }

    &__mobile-title-icon-open {
        transform: rotate(180deg);
    }

    &__mobile-title {
        padding-right: 15px;
        padding-left: 15px;
        font-weight: bold;
    }

    &__icon {
        max-width: 40px;
        max-height: 40px;
        height: 100%;
    }

    &__name {
        text-align: left;
        padding-left: 10px;
    }
}

.map-key-bar__key-column {
    padding-left: 5px;
}

@mixin map-key-bar-desktop {
    .map-key-bar {
        text-align: right;
        background-color: #fff;
        box-shadow: none;
        padding: 0;

        &__title-bar {
            padding: 0;
            display: flex;
            flex-direction: row;
        }

        &__title-icon {
            display: none;
        }

        &__name {
            padding-left: 5px;
            font-size: to-rem(16px);
            line-height: (20 / 16);
        }

        &__key-container {
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            margin-right: -15px;
        }
        
        &__mobile-title-bar {
            display: none;
        }
        
        &__desktop-title-bar {
            padding: 0px;
            display: flex;
            flex-direction: row;
            margin-right: 15px;
            flex-shrink: 0;
        }
    }
}