.live-message-list {
    padding-top: 15px;
    height: 90%;
}

.live-message-list__item {
    margin-top: 12px;
}

.live-message-list__stats {
    font-size: to-rem(16px);
    margin-bottom: 15px;
    letter-spacing: to-rem(-0.25px);
}

$severity-indicator-border-radius: 8px;
@mixin severity-colour($modifier, $colour, $text-colour) {
    .live-message--#{$modifier} {
        &.live-message--active:before {
            background-color: $colour;
        }
    
        .live-message__severity-name {
            color: $text-colour;
        }
    
        &:hover, &.live-message--highlighted {
            border-color: $colour;
        }
    }
}

.live-message {
    border: 2px solid $lightgrey;
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-bottom: 15px;
    border-radius: $severity-indicator-border-radius;
    position: relative;
    background-color: white;
    transition: border-color 400ms linear;
}

.live-message--active, .live-message--no-longer-in-force {
    padding-left: 20px;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        display: block;
        content: '';
        width: 10px;
        border-top-left-radius: $severity-indicator-border-radius;
        border-bottom-left-radius: $severity-indicator-border-radius;
        transition: border-radius 400ms linear;
    }
}

.live-message:hover, .live-message--highlighted {
    border-width: 4px;
    border-color: $altBorderColour;
    padding: 8px;
    padding-bottom: 13px;
    
    &.live-message--no-longer-in-force {
        padding-left: 18px;
    }

    &.live-message--active {
        padding-left: 18px;

        &:before {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            top: -1px;
            left: -1px;
            bottom: -1px;
            width: 9px;
        }
    }
}

.live-message__severity {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid $lightgrey;
}

.live-message__severity-icon {
    height: 32px;
    width: 32px;
    flex-shrink: 0;
    margin-right: 5px;
}

.live-message__severity-name {
    font-weight: 500;
    color: $darkgrey3;
    font-size: to-rem(15px);
    line-height: 1.25;
    letter-spacing: to-rem(-0.1px);    
}

.live-message__name {
    font-weight: 700;
    line-height: 1.25;
    font-size: to-rem(16px);
    letter-spacing: to-rem(-0.25px);
    margin-bottom: 8px;
    display: block;
}

.live-message__date {
    display: block;
    font-size: to-rem(15px);
    line-height: (22 / 15);
    letter-spacing: to-rem(-0.25px);
    font-weight: 400;
    color: #666;
}

.live-message__details {
    padding-top: 10px;
}

.live-message__issued,
.live-message__updated {
    font-size: inherit;
    line-height: inherit;
}

.live-message__view-details-button {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 5px;
    padding-right: 5px;
    font-size: to-rem(14px);
    line-height: 20px;
    letter-spacing: to-rem(-0.25px);
    margin-left: auto;
    margin-top: 5px;
}

.live-message--hidden {
    display: none;
}

@include severity-colour("severe-flood-warning", $severe-flood-warning-colour, $severe-flood-warning-text-colour);
@include severity-colour("flood-warning", $flood-warning-colour, $flood-warning-text-colour);
@include severity-colour("flood-alert", $flood-alert-colour, $flood-alert-text-colour);

@include desktop {
    .live-message-list {
        padding-top: 30px;
    }

    .live-message-list__stats {
        margin-bottom: 30px;
    }
}

@mixin live-message-stacked-horizontally {
    .live-message {
        flex-direction: row;
        padding-left: 25px;
        padding-bottom: 10px;
    }

    .live-message__severity {
        flex-shrink: 0;
        width: to-rem(165px);
        padding-right: 10px;
        padding-bottom: 0;
        padding-top: 3px;
        padding-bottom: 3px;
        margin-right: 20px;
        border-bottom: none;
        border-right: 4px solid $lightgrey;
        align-self: center;
    }

    .live-message__details {
        flex-grow: 1;
        flex-basis: 0;
        padding-top: 0;
    }

    .live-message__severity-icon {
        height: 54px;
        width: 54px;
        margin-right: 10px;
    }

    .live-message__top {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid $lightgrey;
        align-items: center;
        padding-bottom: 9px;
        margin-bottom: 10px;
        margin-top: 4px;
    }

    .live-message__bottom {
        display: flex;
        flex-direction: row;
    }

    .live-message__dates {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .live-message__view-details-button {
        margin-left: auto;
        margin-top: 0;
        white-space: nowrap;
        align-self: flex-start;
    }

    .live-message__date {
        display: inline-block;
        margin-right: 30px;
        font-size: to-rem(15px);
        margin-right: 30px;
        white-space: nowrap;

        &:last-child {
            margin-right: 0;
        }
    }

    .live-message__name {
        display: block;
        margin-right: auto;
        margin-bottom: 0;
        font-size: to-rem(16px);
    }

    .live-message:hover, .live-message--highlighted {
        padding-left: 23px;
        padding-bottom: 8px;
        
        &.live-message--active {
            padding-left: 23px;
        }
    }
}

@include tablet {
    .view-container:not(.view-container--combined-view) {
        @include live-message-stacked-horizontally;
    }
}

@include xldesktop {
    .view-container--combined-view {
        @include live-message-stacked-horizontally;

        .live-message__date {
            margin-right: 30px;
        }

        .live-message__bottom {
            display: block;
        }

        .live-message__view-details-button {
            margin-left: 0;
            margin-top: 5px;
        }
    }

    .live-message-list {
        max-width: 960px;
    }
}