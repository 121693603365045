.atoz {
    .live-message-list {
        max-width: 100%;
    }

    .live-message {
        flex-direction: row;

        .live-message {
            &__severity {
                border-bottom: none;
                padding-bottom: 0;
                width: 140px;
                border-right: 1px solid #e9e9ea;
                padding-right: 10px;
            }

            &__details {
                padding-left: 10px;
                width: 80%;
                position: relative;
            }

            &__dates {
                padding-top: 5px;
                display: flex;
                flex-direction: column;

                @include tablet {
                    flex-direction: row;
                }

                .live-message__date {
                    margin-right: 5px;
                }
            }

            &__description {
                display: block;
                font-size: to-rem(15px);
                line-height: (22 / 15);
                letter-spacing: to-rem(-0.25px);
                font-weight: 400;
                color: #666;
                margin-top: to-rem(5px);
            }

            &__top {
                border-bottom: 1px solid #e9e9ea;
            }

            &__bottom {
                a {
                    position: absolute;
                    top: 0;
                    right: 0;

                    @media screen and (max-width: 365px) {
                        position: static;
                    }
                }
            }
        }
    }

    .search {
        .search__container {
            padding-bottom: 20px;

            .search__row {
                .search__bar {
                    .search__search-field {
                        display: flex;

                        &-control-container {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            flex-grow: 1;
                            align-items: center;
                            
                            .search {
                                &__search-input {
                                    display: flex;
                                    flex-direction: row;
                                    padding-bottom: 5px;

                                    #searchInput {
                                        background-image: none;
                                    }
                                }

                                &__return-landing {
                                    .button--secondary {
                                        color: #007485;
                                        border-color: #007485;
                                    }
                                }
                            }

                            @include tablet {
                                flex-direction: row;

                                .search {
                                    &__search-input {
                                        flex-grow: 0;
                                        width: 50%;
                                        padding-bottom: 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .live-message-list {
        button {
            background: none;
            border: none;
            text-decoration: none;
            color: #007485;
            font-size: 1rem;
            padding: 0;
            text-align: left;
            font-weight: bold;
        }

        .live-message {
            &__top {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
            }
        }
    }
}