﻿.pagination {
    flex-direction: column;
    margin-top: 5px;

    .pagination__per-page-selector {
        margin-bottom: 15px;
    }

    .pagination__page-link {
        padding-top: 10px;
        padding-bottom: 10px;

        &:not(.pagination__page-link--disabled) {
            color: #007485;
        }
    }
}

@include tablet {
    .pagination {
        flex-direction: row;

        .pagination__per-page-selector {
            margin-bottom: 0;
        }
    }
}