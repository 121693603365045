.last-updated {
    font-size: to-rem(15px);
    line-height: (19 / 15);
    letter-spacing: to-rem(-0.25px);
    color: #333;

    span, a {
        // Override the silly base styling on <span>.......
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
    }

    span {
        font-weight: inherit;
    }
}

.last-updated__error {
    color: $errortextred;
    font-weight: bold;
}

@include desktop {
    .last-updated__line {
        display: inline-block;
    }

    
}