﻿.cluster-icon-container {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
}

.cluster-icon-text {
    width: 100%;
    color: black;
    text-align: center;
    font-size: 1.1em;
    margin: 0 auto;
    padding: 16px 0 0 2px;
}