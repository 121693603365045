﻿.health-check {

    &.banner {
        background-color: $health-check-banner-background;
        position: fixed;
        left: calc(50vw - 300px);
        bottom: 0;
        padding: 20px;
        border: 2px solid #000;
        z-index: 1000;
        width: 600px;
    }

    .data-switch {
        text-align: center;

        form {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 10px;
            text-align: left;
            width: 100%;

            button {
                margin-left: 15px;
            }
        }
    }
}
