/**
 * This is a copy of site.scss from @nrw.core/styles with
 * un-used parts commented out, and paths updated to point to
 * NPM.
 */

// base config
@import "../../node_modules/@nrw.core/styles/base/reset";
@import "../../node_modules/@nrw.core/styles/base/functions";
@import "../../node_modules/@nrw.core/styles/base/variables";
@import "../../node_modules/@nrw.core/styles/base/config";
// FWAA-specific config overrides here
@import "./nrw.core.styles.config";
@import "../../node_modules/@nrw.core/styles/base/mixins";
@import "../../node_modules/@nrw.core/styles/base/base";

// Font
@import "../../node_modules/@nrw.core/styles/base/font";

// Layout
@import "../../node_modules/@nrw.core/styles/base/cols";

// Master Page
//@import "../../node_modules/@nrw.core/styles/base/site-navigation";
@import "../../node_modules/@nrw.core/styles/base/page-banner";
//@import "../../node_modules/@nrw.core/styles/base/sub-header";

// Forms
@import "../../node_modules/@nrw.core/styles/base/tables";
//@import "../../node_modules/@nrw.core/styles/base/mobile-table-summary";

// Page specific
//@import "../../node_modules/@nrw.core/styles/base/filter-bars";
@import "../../node_modules/@nrw.core/styles/base/notification-bar";
//@import "../../node_modules/@nrw.core/styles/base/service-card";
//@import "../../node_modules/@nrw.core/styles/base/alerts";
//@import "../../node_modules/@nrw.core/styles/base/service-application-summary";
//@import "../../node_modules/@nrw.core/styles/base/signout";

// Overrides
//@import "../../node_modules/@nrw.core/styles/base/modal-form";
//@import "../../node_modules/@nrw.core/styles/base/azure-overrides";
//@import "../../node_modules/@nrw.core/styles/base/mvc-overrides";

// Vendor
@import "../../node_modules/@nrw.core/styles/base/vendor/jquery-ui/themes/base/tooltip";
//@import "../../node_modules/@nrw.core/styles/base/vendor/jquery-ui/themes/base/datepicker";
//@import "../../node_modules/@nrw.core/styles/base/vendor/swiper/swiper.min";

// Master Page
@import "../../node_modules/@nrw.core/styles/base/header";
@import "../../node_modules/@nrw.core/styles/base/banner";
@import "../../node_modules/@nrw.core/styles/base/footer";

//@import "../../node_modules/@nrw.core/styles/base/aboutUs";
@import "../../node_modules/@nrw.core/styles/base/browserSupportNotification";

// Generic items
@import "../../node_modules/@nrw.core/styles/base/accessibility";
@import "../../node_modules/@nrw.core/styles/base/inputs";
//@import "../../node_modules/@nrw.core/styles/base/form";
@import "../../node_modules/@nrw.core/styles/base/layout";
@import "../../node_modules/@nrw.core/styles/base/pagination";
//@import "../../node_modules/@nrw.core/styles/base/datepicker";
//@import "../../node_modules/@nrw.core/styles/base/loadingSpinner";
@import "../../node_modules/@nrw.core/styles/components/tooltips/sass/tooltips";
@import "../../node_modules/@nrw.core/styles/base/modal";
@import "../../node_modules/@nrw.core/styles/base/accordion";
@import "../../node_modules/@nrw.core/styles/base/feedbackSection";

// Partials
@import "../../node_modules/@nrw.core/styles/base/view";
@import "../../node_modules/@nrw.core/styles/base/tabs";
@import "../../node_modules/@nrw.core/styles/base/search";
@import "../../node_modules/@nrw.core/styles/base/utils";
