﻿// popup override styles
$popup-edge-radius: 5px;

.leaflet-customPopup {
    position: absolute;
    text-align: left;
    max-height: 100vh;
    max-width: 320px !important;
}

.leaflet-customPopup-content-wrapper {
    padding: 1px;
    text-align: left;
}

.leaflet-customPopup-content {
    margin: 4px 4px;
}

.leaflet-customPopup-tip {
    width: 15px;
    height: 15px;
    padding: 1px;
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);

    &-n {
        margin: -8px auto 0;
    }

    &-s {
        margin: 12px auto 0;
    }

    &-se {
        margin: 12px 12px 12px -8px;
        overflow: hidden;
    }

    &-sw {
        margin: 14px 12px 12px 32px;
        overflow: hidden;
    }

    &-ne {
        margin: -8px 12px 12px -8px;
        overflow: hidden;
    }

    &-nw {
        margin: -8px 12px 12px 32px;
        overflow: hidden;
    }
}

a.leaflet-customPopup-close-button {
    position: absolute;
    top: 0px;
    right: 6px;
    text-align: center;
    font-size: 28px;
    font-family: 'Impact' !important;
    color: $black;
    text-decoration: none;

    &:hover {
        font-weight: 900;
    }

    &-s, &-se, &-sw {
        top: 18px;
        right: 6px;
    }
}

.leaflet-customPopup-content p {
    margin: 18px 0;
}

.leaflet-customPopup-scrolled {
    overflow: auto;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
}

.leaflet-customPopup-tip-container {
    margin: 0 auto;
    width: 40px;
    height: 20px;
    left: 0;
    position: relative;
    overflow: hidden;

    &-se, &-ne {
        margin-left: 0;
    }

    &-sw, &-nw {
        margin-right: 0;
    }
}

.leaflet-customPopup-content-wrapper, .leaflet-customPopup-tip {
    background: white;
    box-shadow: 0 3px 10px #888;
    -moz-box-shadow: 0 3px 10px #888;
    -webkit-box-shadow: 0 3px 14px #999;
}

.leaflet-customPopup-content-wrapper {
    -moz-border-radius: $popup-edge-radius;
    -webkit-border-radius: $popup-edge-radius;
    border-radius: $popup-edge-radius;

    &-se {
        -moz-border-radius: 0 $popup-edge-radius $popup-edge-radius $popup-edge-radius;
        -webkit-border-radius: 0 $popup-edge-radius $popup-edge-radius $popup-edge-radius;
        border-radius: 0 $popup-edge-radius $popup-edge-radius $popup-edge-radius;
    }

    &-sw {
        -moz-border-radius: $popup-edge-radius 0 $popup-edge-radius $popup-edge-radius;
        -webkit-border-radius: $popup-edge-radius 0 $popup-edge-radius $popup-edge-radius;
        border-radius: $popup-edge-radius 0 $popup-edge-radius $popup-edge-radius;
    }

    &-nw, &-w {
        -moz-border-radius: $popup-edge-radius $popup-edge-radius 0 $popup-edge-radius;
        -webkit-border-radius: $popup-edge-radius $popup-edge-radius 0 $popup-edge-radius;
        border-radius: $popup-edge-radius $popup-edge-radius 0 $popup-edge-radius;
    }

    &-ne, &-e {
        -moz-border-radius: $popup-edge-radius $popup-edge-radius $popup-edge-radius 0;
        -webkit-border-radius: $popup-edge-radius $popup-edge-radius $popup-edge-radius 0;
        border-radius: $popup-edge-radius $popup-edge-radius $popup-edge-radius 0;
    }
}