a[href]:not([tabindex='-1']):focus,
area[href]:not([tabindex='-1']):focus,
input:not([disabled]):not([tabindex='-1']):focus,
select:not([disabled]):not([tabindex='-1']):focus,
textarea:not([disabled]):not([tabindex='-1']):focus,
button:not([disabled]):not([tabindex='-1']):focus,
iframe:not([tabindex='-1']):focus,
[tabindex]:not([tabindex='-1']):focus,
[contentEditable=true]:not([tabindex='-1']):focus,
a.accordion__title-bar:not([tabindex-1]):focus
{
    background-color: #FD0 !important;
    border-color: transparent !important;
    box-shadow: 0 -2px #FFDD00, 0 4px #0B0C0C !important;
    color: #0B0C0C !important;
    outline: none !important;

    h2 {
        color: #0B0C0C !important;
    }
}

#skiplink-container {
    display: flex;

    @media screen and (min-width: 768px) {
        .skiplink:focus {
            left: 0;
            z-index: 1;
            background-color: #FD0;
            border-color: transparent;
            box-shadow: 0 -2px #FFDD00, 0 4px #0B0C0C;
            color: #0B0C0C;
            margin: 3px;
            padding: 3px;
            position: relative;
        }
    }

    @media screen and (min-width: 768px) {
        .skiplink {
            position: absolute;
            left: -9999em;
            text-decoration: none;
            outline: none;
        }
    }
}

.footer {
    &__site-contact {
        &-button {
            border: 1px solid #fff !important;
        }
    }
}