.landing-page {
    @at-root .no-js &, .js-not-loaded & {
        .tabs {
            display: none;
        }

        .view-container {
            box-shadow: none;
            height: auto;
        }

        .view-container__list {
            display: block;
            flex-basis: 100%;
        }

        .view-container__map {
            display: none;
        }

        .search__bar {
            display: none;
        }

        .search__location-button {
            display: none;
        }

        .search__controls {
            width: 100%;
        }

        .search__how-to-use-container {
            display: none;
        }

        .search__last-updated-desktop {
            text-align: left;
            margin-left: 0;
        }

        .flood-warning-banner {
            min-height: 90px;
            margin-left: 0;
            margin-top: 0;
        }

        .flood-warning-count--active {
            cursor: default;
        }

        .flood-warning-count__dropdown-icon {
            display: none;
        }

        .live-message-list__pagination {
            display: none;
        }
    }

    @include desktop {
        @at-root .no-js &, .js-not-loaded & {
            .flood-warning-banner {
                padding-bottom: 30px;
            }
        }
    }

    @media screen and (max-width: #{$desktopWidth + 19}) {
        .view-container--combined-view {
            .view-container__list {
                display: none;
            }

            .view-container__map {
                flex-basis: 100%;
            }
        }
    }

    @media screen and (min-height: 550px) {
        .view-container--map-view {
            min-height: 600px;
        }
    }

    .view-container {
        width: 100%;
        margin: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        height: auto;
        min-height: none;

        .esri-view {
            min-height: 500px;
            max-height: 800px;
            height: 80vh;
        }

        &--map-view {
            max-height: none;
        }

        &--combined-view {
            max-height: none;

            .live-message-list {
                min-height: 500px;
                max-height: 800px;
                height: 80vh;
                position: relative;
            }

            // Pagination resizing for desktop combined view
            @media screen and (min-width: $desktopWidth + 20px) and (max-width: $xldesktopWidth + 20px) {
                .pagination__per-page-selector {
                    margin-right: 10px;
                }

                .pagination__per-page {
                    margin-left: 8px;
                }

                .pagination__page-link {
                    padding-left: 4px;
                    padding-right: 4px;
                }
            }
        }
    }

    @include tablet {
        .view-container {
            -webkit-box-shadow: 0px 10px 20px -10px #ccc;
            box-shadow: 0px 10px 20px -10px #ccc;
        }

        .view-container__key {
            @include map-key-bar-desktop;
        }
    }

    @include desktop {
        .view-container {
            width: 95%;
            max-width: 1040px;
            margin: auto;

            &--combined-view {
                margin-top: 20px;
            }
        }
    }

    @include xldesktop {
        .view-container {
            width: 90%;
            max-width: 1500px;
        }
    }
}
