﻿.easy-button-button{
    padding: 0;
    background-color: white;
    width: 40px;
    height: 40px;
    border: none;

    &:hover{
        background-color: darken(white,5%);
    }

    span img {
        width: 26px;
        height: 30px;
        padding-top: 6px;
    }
}

/*overriding the default zoom control styles so they look like the home button.*/
.leaflet-touch .leaflet-bar a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 35px;

    &:hover {
        background-color: darken(white,5%);
        width: 40px;
        height: 40px;
        line-height: 40px;
    }
}

/*Duplicate rules needed to supercede stock leaflet CSS in Chrome and Edge*/
.leaflet-bar a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 35px;

    &:hover {
        background-color: darken(white,5%);
        width: 40px;
        height: 40px;
        line-height: 40px;
    }
}
