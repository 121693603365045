.search__last-updated-desktop, .search__last-updated-mobile {
    font-size: to-rem(15px);
}

.search__last-updated-mobile {
    display: block;
    margin-top: 10px;
}

.search__last-updated-desktop {
    display: none;
}

.search__last-updated-desktop {
    align-self: center;
}

.search-results-section {
    .search-results__container {
        .search-results_error {
            color: red;
        }
    }

    .search-results__text:not(.display-none) {
        display: inline-block;
    }
}

.search__autocomplete {
    z-index: 2000;
    left: 0;
}

.search-results button {
    background: none;
    border: none;
    text-decoration: none;
    color: #007485;
    font-size: 1rem;
    padding: 0;
    text-align: left;
}

.search-results button.search-results__clear {
    font-weight: bold;
}

@media #{$searchBreakpoint} {
    .search__container {
        padding-top: 20px;
    }

    .search__last-updated-mobile {
        display: none;
    }

    .search__last-updated-desktop {
        display: block;
    }

    .search__last-updated-desktop {
        margin-bottom: 20px;
    }
}

@include lt-desktop {
    .search__autocomplete {
        max-width: 100%;
    }
}

@include lt-xldesktop {
    .search-results {
        &--autocomplete {
            .search-results__type-stats {
                display: block;
                float: none;
                margin-left: 0;
                margin-top: 5px;
            }
        }
    }
}
