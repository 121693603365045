
@mixin severity-colour($modifier, $colour, $text-colour) {
    .details__severity-name--#{$modifier} {
            color: $text-colour;
    }
}

@include severity-colour("severe-flood-warning", $severe-flood-warning-colour, $severe-flood-warning-text-colour);
@include severity-colour("flood-warning", $flood-warning-colour, $flood-warning-text-colour);
@include severity-colour("flood-alert", $flood-alert-colour, $flood-alert-text-colour);

.details {
    // added support for IE11
    wbr:after {
        content: "\00200B";
    }

    color: #58595B;

    &__container {
        margin-top: -25px;
        position: relative;

        &__inner {
            margin-bottom: 36px;
        }
    }

    &__severity {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    &__severity-icon {
        max-height: 41px;
        max-width: 41px;
        vertical-align: middle;
    }

    &__severity-name {
        letter-spacing: to-rem(-0.005px);
        margin-left: 8px;
        margin-right: 20px;
        margin-bottom: 0;
        font-size: to-rem(30px);
        font-weight: 700;
        line-height: 34px;

        &--no-longer-in-force {
            margin-left: 0px;
        }
    }

    &__share-and-dates {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 2px solid #E9E9EA;
        margin-top: 10px;
        margin-bottom: 20px;
        align-content: space-between;
        font-size: to-rem(15px);
        align-items: center;
        padding-bottom: 10px;
    }

    &__date {
        margin-right: 15px;
        white-space: nowrap;
    }

    &__date, &__issued, &__updated {
        font-size: to-rem(15px);
    }

    &__null-date {
        padding-left: 20px;
        padding-right: 50px;
    }

    &__share-button {
        padding-top: 4px;
        padding-bottom: 4px;
        border-color: #007485;
        color: #007485;
    }

    &__description {
        color: #58595B;
        font-size: to-rem(16px);
        line-height: (24 / 16);
        margin-bottom: 10px;
        border-bottom: 2px solid #E9E9EA;
        padding-bottom: 10px;
    }

    &__rimtext {
        color: #58595B;
        font-size: to-rem(16px);
        line-height: (24 / 16);
        margin-bottom: 10px;
        border-bottom: 2px solid #E9E9EA;
        padding-bottom: 10px;
    }

    &__river-levels {
        color: #58595B;
        font-size: to-rem(16px);
        line-height: (24 / 16);
        vertical-align: middle;
        margin-bottom: 20px;
    }

    &__phone {
        margin-bottom: 32px;
    }

    &__signup-button {
        box-sizing: border-box;
        border-color: #007485;
        border-width: 3px;
        background-color: white;
        color: #007485;
        margin-bottom: 30px;
        width: 100%;
    }

    &__home {
        height: 15px;
        color: #007485;
        font-size: to-rem(16px);
        line-height: (26 / 16);
        margin-bottom: 59px;
    }

    &__map {
        margin-bottom: 30px;
        margin-left: -($gutterWidth / 2);
        margin-right: -($gutterWidth / 2);
    }

    &__accordion {
        margin-bottom: 10px;
    }

    &__last-updated {
        margin-bottom: 15px;
        color: inherit;
    }

    &__key {
        border-bottom: 2px solid #E9E9EA;
    }
}

.floodline-phone {
    font-size: to-rem(23px);
    font-weight: 700;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    div {
        margin-right: 5px;
    }
}

.floodline-service-notice {
    font-size: to-rem(17px);
    font-weight: 500;
}

.accordion {   
    &__loading {
        margin-left: auto;
        margin-right: auto;
    }
}

.esri-view {
    min-height: 388px;
}

.severity-info__list-item{
    font-size: to-rem(16px);
    line-height: (24 / 16);
    list-style-type: disc;
    color: #58595B;
    margin-bottom: 20px;
    margin-left: 20px;
}

.severity-name__info-link {
    margin-left: auto;
}

.modal {
    &__paragraph {
        margin-bottom: 0px;
    }
}

.related-areas-accordion__content {
    font-size: to-rem(16px);

    p {
        margin-top: 15px;
        margin-left: 15px;
    }

    ul {
        margin-top: 15px;
        margin-left: 15px;
    }

    li {
        list-style: disc outside;
    }
}
   
.statuses-table{
    font-size: to-rem(16px);
    margin-top: 15px;

    td {
        padding-top: 15px;
        padding-bottom: 15px;
        line-height: 1;
    }
}

.limit-text {
    font-size: to-rem(16px);
    margin-top: 35px;
}

.no-results {
    margin-top: 15px;
}

.accordion__title-bar-container {
    display: flex;
    background-color: #007485;
}

@media screen and (min-width: 500px) {
    .details {
        &__date {
            wbr {
                display: none;
            }
        }
    }
}

@include tablet {
    .details {
        &__container {
            margin-top: -15px;
        }

        &__signup-button {
            width: auto;
        }

        &__severity-name {
            font-size: to-rem(30px);
            vertical-align: middle;
            margin-left: 13px;
            margin-right: 20px;
            
            &--no-longer-in-force {
                margin-left: 0px;
            }
        }

        &__severity-icon {
            max-height: 52px;
            max-width: 52px;
            vertical-align: middle;
        }
    }
}

@media 
    // Show desktop key only in mobile layout if there's enough width
    screen and (min-width: #{$tabletWidth + 20}) and (max-width: #{$desktopWidth + 19})
{
    .details {
        @include map-key-bar-desktop;

        &__key .map-key-bar {
            text-align: center;
        }
    }
}


    
@media 
    // But then go back to mobile key when we hit desktop design, then back to desktop key when there's enough width
    screen and (min-width: #{$desktopWidth + 20})
{
    .details {
        @include map-key-bar-desktop;

        &__key .col {
            padding-left: 0;

            @media screen and (min-width: 980px) and (max-width: 1060px) {
                .map-key-bar__column {
                    padding-right: 5px;
                }

                .map-key-bar__desktop-title-bar {
                    padding-left: 3px;
                    margin-right: 3px;
                }
            }

            @media screen and (min-width: 920px) and (max-width: 980px) {
                .map-key-bar__column {
                    padding-right: 2px;
                }

                .map-key-bar__desktop-title-bar {
                    padding-left: 2px;
                    margin-right: 2px;
                }

                .map-key-bar__name {
                    font-size: to-rem(14.5px);
                }
            }
        }

        .map-key-bar__desktop-title-bar {
            padding-left: 10px;
        }
    }
}

@media screen and (max-width: #{$desktopWidth + 19}) {
    .esri-view {
        min-height: 360px;
        height: 388px;
    }
}

@include desktop {
    .details {
        &__container {
            margin-top: -5px;
        }
        
        &__severity-name {
            font-size: to-rem(30px);
            vertical-align: middle;
            margin-left: 13px;
            margin-right: 20px;
                
            &--no-longer-in-force {
                margin-left: 0px;
            }
        }

        &__left {
            max-width: 50%;
        }

        &__rimtext {
            margin-bottom: 50px;
        }

        &__desktop-links {
            margin-top: 50px;
        }

        &__map {
            position: absolute;
            top: 0;
            right: $gutterWidth;
            width: calc((50% - #{$gutterWidth}));
            height: 540px;
            display: flex;
            flex-direction: column;
        }
    }
}