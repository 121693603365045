.map-loading {
    display: none;
    position: absolute;
    right: 10px;
    top: 10px;
    height: 48px;
    width: 48px;
    z-index: 1000;
}

.map-loading--visible {
    display: block;
}