.live-message-popup {
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 8px;
    padding-bottom: 8px;

    .button {
        background-color: $lightgreen;
        color: white;
        padding: 5px;
        margin-top: 10px;

        &:hover {
            background-color: darken($lightgreen, 10%);
            color: white;
        }

        #turn-off-text {
            display: none;
        }
    }
}

.live-message-popup__name,
.live-message-popup__severity-name {
    font-size: to-rem(16px);
    line-height: (23 / 16);
    font-weight: 600;
    letter-spacing: to-rem(-0.25px);
}

.live-message-popup__severity-name {
    margin-bottom: 2px;
    color: #333333;
}

.live-message-popup__name {
    display: block;
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid $lightgrey;
}

.live-message-popup__date {
    font-size: to-rem(15px);
    line-height: (22 / 15);
    letter-spacing: to-rem(-0.25px);
    color: #666666;

    span {
        font: inherit;
    }
}

.live-message-popup__view-details-button {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 5px;
    padding-right: 5px;
    font-size: to-rem(14px);
    line-height: (20 / 14);
    letter-spacing: to-rem(-0.25px);
    margin-top: 7px;    
}


.live-message-popup-container {
    &.esri-popup--aligned-bottom-center {
        transform: translate(-4px, 16px)
    }
    &.esri-popup--aligned-top-center {
        transform: translate(-4px, -16px)
    }

    &:not(.esri-popup--shadow) {
        box-shadow: none;
    }
}

.live-message-popup-container--mobile {
   
    max-width: calc(100vw - 10px);

    &.esri-popup--aligned-bottom-left {
        transform: translate(-33px, -40px);

        .esri-popup__pointer {
            transform: rotate(90deg);
            top: 18px;
        }

        .esri-popup__pointer-direction {
            width: 37px;
            height: 37px;
            transform: scale(.375, 2) rotate(45deg);
            bottom: -26px;
            left: -17px;
        }
    }

    &.esri-popup--aligned-bottom-center {
        transform: translateY(50px);

        .esri-popup__pointer-direction {
            width: 37px;
            height: 37px;
            transform: scale(.375, 2) rotate(45deg);
            bottom: -26px;
            left: -17px;
        }
    }
}

@include desktop {
    .live-message-popup {

    }

    .live-message-popup__name,
    .live-message-popup__severity-name {
        line-height: (26 / 16);
    }
}