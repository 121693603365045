$open-shadow: 0 1px 3px 0px rgba(0,0,0,0.135);

//#region .flood-warning-banner
.flood-warning-banner {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    margin-top: 10px;
    position: relative;
}

.flood-warning-banner__item {
    width: calc((100% / 3) - 5px);
    flex-grow: 1;
    flex-shrink: 0;
    margin-left: 5px;

    &:first-child {
        margin-left: 0;
    }
}
//#endregion

$open-border-width: 2px;

//#region .flood-warning-count
.flood-warning-count {
    display: block;
    background-color: white;
    padding-left: 5px - $open-border-width;
    padding-right: 5px - $open-border-width;
    padding-top: 5px;
    padding-bottom: 5px;
    border-left: $open-border-width solid transparent;
    border-right: $open-border-width solid transparent;
    height: 100%;
    position: relative;
    user-select: none;
}

.flood-warning-count:hover {
    text-decoration: none;
}


.flood-warning-count__count {
    font-size: to-rem(32px);
    line-height: 1;
    font-weight: 700;
    color: #626264;
    text-align: right;
    display: block;
    flex-grow: 1;
}

.flood-warning-banner--3-digits .flood-warning-count__count {
    font-size: to-rem(27px);
}

.flood-warning-count__left {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    flex-shrink: 0;
}

.flood-warning-count__right {
    padding-right: 20px;
}

.flood-warning-count__text {
    font-size: to-rem(13px);
    font-weight: 500;
    line-height: (16 / 13);
    letter-spacing: to-rem(-0.1px);
    display: inline-table;
    color: #565655;
}

.flood-warning-count__icon {
    height: 41px;
    width: 41px;
    display: block;
    flex-shrink: 0;
}

.flood-warning-count__dropdown-icon {
    display: none;
    position: absolute;
    bottom: 5px;
    right: 5px;
    height: 14px;
    width: 14px;
    transform-origin: center center;
}

.flood-warning-count--active {
    cursor: pointer;

    .flood-warning-count__dropdown-icon {
        display: block;
    }

    &.flood-warning-count--severe-flood-warning {
        .flood-warning-count__count {
            color: $severe-flood-warning-colour;
        }
    }

    &.flood-warning-count--flood-warning {
        .flood-warning-count__count {
            color: $flood-warning-colour;
        }
    }

    &.flood-warning-count--flood-alert {
        .flood-warning-count__count {
            color: $flood-alert-colour;
        }
    }
}

.flood-warning-count--open {
    border-left-color: $open-flood-warning-count-border-colour;
    border-right-color: $open-flood-warning-count-border-colour;
    box-shadow: $open-shadow;

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: -$open-border-width;
        right: -$open-border-width;
        background-color: white;
        height: 7px;
        z-index: 101;
        border-left: $open-border-width solid $open-flood-warning-count-border-colour;
        border-right: $open-border-width solid $open-flood-warning-count-border-colour;
    }

    .flood-warning-count__dropdown-icon {
        transform: rotate(180deg);
    }

    &:before {
        content: '';
        position: absolute;
        display: block;
        top: -3px;
        height: 6px;
        left: -1px;
        right: -1px;
    }

    &.flood-warning-count--severe-flood-warning:before {
        background-color: $severe-flood-warning-colour;
    }

    &.flood-warning-count--flood-warning:before {
        background-color: $flood-warning-colour;
    }

    &.flood-warning-count--flood-alert:before {
        background-color: $flood-alert-colour;
    }
}
//#endregion

//#region .flood-warning-count-out-of-force-desktop
.flood-warning-count-out-of-force-desktop {
    font-size: to-rem(16px);
    line-height: 1;
    font-weight: 500;
    letter-spacing: to-rem(-0.25px);
    color: $darkgrey3;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-left: 2px solid transparent;
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    cursor: pointer;
}

.flood-warning-count-out-of-force-desktop__dropdown-icon {
    height: 16px;
    width: 16px;
    vertical-align: middle;
    margin-left: 5px;
}

.flood-warning-count-out-of-force-desktop__count {
    line-height: 1;
}

.flood-warning-count-out-of-force-desktop--open {
    background-color: white;
    border-left-color: $open-flood-warning-count-border-colour;
    border-top-color: $open-flood-warning-count-border-colour;
    border-right-color: $open-flood-warning-count-border-colour;
    z-index: 101;

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: -$open-border-width;
        right: -$open-border-width;
        background-color: white;
        height: 2px;
        z-index: 101;
        border-left: $open-border-width solid $open-flood-warning-count-border-colour;
        border-right: $open-border-width solid $open-flood-warning-count-border-colour;
    }
    
    .flood-warning-count-out-of-force-desktop__dropdown-icon {
        transform: rotate(180deg);
    }
}
//#endregion

.flood-warning-banner__out-of-force {
    position: absolute;
    right: 0;
    height: 40px;
    top: calc(100% + 4px);
    display: none;
}

//#region .flood-warning-count-out-of-force-mobile
.flood-warning-count-out-of-force-mobile {
    border-bottom: 2px solid $borderlightgrey;
}

.flood-warning-count-out-of-force-mobile__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 64px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    cursor: pointer;
    user-select: none;
}

.flood-warning-count-out-of-force-mobile__count {
    font-size: to-rem(36px);
    font-weight: 700;
    color: $darkgrey;
    margin-right: 15px;
    display: block;
}

.flood-warning-count-out-of-force-mobile__text {
    font-size: to-rem(18px);
    line-height: 1.25;
    font-weight: 500;
    color: $darkgrey3;
    display: block;
    margin-right: auto;
}

.flood-warning-count-out-of-force-mobile__dropdown-icon {
    height: 16px;
    width: 16px;
    vertical-align: middle;
    margin-left: 15px;
    margin-right: 10px;
    flex-shrink: 0;
}

.flood-warning-count-out-of-force-mobile__list {
    margin-bottom: 10px;
    display: none;
}

.flood-warning-count-out-of-force-mobile__list-item-link {
    border-top: 1px solid $lightgrey;
    display: block;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: to-rem(18px);
}

.flood-warning-count-out-of-force-mobile__list-item {
    &:first-child {
        .flood-warning-count-out-of-force-mobile__list-item-link {
            border-top: none;
        }
    }
}

.flood-warning-count-out-of-force-mobile--open {
    background-color: white;
    border-left-color: $open-flood-warning-count-border-colour;
    border-top-color: $open-flood-warning-count-border-colour;
    border-right-color: $open-flood-warning-count-border-colour;
    z-index: 101;

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: -$open-border-width;
        right: -$open-border-width;
        background-color: white;
        height: 2px;
        z-index: 101;
        border-left: $open-border-width solid $open-flood-warning-count-border-colour;
        border-right: $open-border-width solid $open-flood-warning-count-border-colour;
    }

    .flood-warning-count-out-of-force-mobile__dropdown-icon {
        transform: rotate(180deg);
    }
}

.flood-warning-count-out-of-force-mobile__inner--open {
    .flood-warning-count-out-of-force-mobile__dropdown-icon {
        transform: rotate(180deg);
    }
}

.flood-warning-count-out-of-force-mobile__list--open {
    display: block;
}
//#endregion

$flood-warning-count-dropdown-padding: 8px;

//#region .flood-warning-count-dropdown
.flood-warning-count-dropdown {
    background-color: white;
    border: $open-border-width solid $open-flood-warning-count-border-colour;
    display: none;
    box-shadow: $open-shadow;
}

.flood-warning-count-dropdown__severity {
    font-size: to-rem(16px);
    line-height: (38 / 32);
    letter-spacing: to-rem(-0.25px);
    color: #666666;
    font-weight: 700;
    padding-top: 15px;
    padding-bottom: 10px;
    padding-left: $flood-warning-count-dropdown-padding;
    padding-right: $flood-warning-count-dropdown-padding;
    border-bottom: 1px solid $lightgrey;
}

.flood-warning-count-dropdown__target-area-list-item-link {
    border-bottom: 1px solid $lightgrey;
    display: block;
    padding-left: $flood-warning-count-dropdown-padding;
    padding-right: $flood-warning-count-dropdown-padding;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: to-rem(16px);
    line-height: (38 / 32);
    letter-spacing: to-rem(-0.25px);
    font-weight: 500;
}

.flood-warning-count-dropdown__target-area-list-item:last-child {
    .flood-warning-count-dropdown__target-area-list-item-link {
        border-bottom: none;
    }
}

.flood-warning-count-dropdown__target-area-list {
    max-height: 400px;
    overflow: auto;
}

.flood-warning-count-dropdown--open {
    display: block;
}

.flood-warning-banner__out-of-force-count-dropdown.flood-warning-count-dropdown--open {
    display: none;
}

//#endregion

.flood-warning-banner__count-dropdown {
    position: absolute;
    z-index: 1100;
    top: calc(100% + 5px);
    left: 0;
    right: 0;
}

.flood-warning-banner__out-of-force-count-dropdown {
    position: absolute;
    z-index: 1100;
    top: calc(100% + 43px);
    left: 0;
    right: 0;
}

.no-js {
    .flood-warning-count__dropdown-icon,
    .flood-warning-count-out-of-force-mobile__dropdown-icon,
    .flood-warning-count-out-of-force-desktop__dropdown-icon {
        display: none;
    }
}

@media screen and (min-width: 375px) {

    .flood-warning-count {
        padding-left: 8px - $open-border-width;
        padding-right: 8px - $open-border-width;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .flood-warning-count__count {
        font-size: to-rem(36px);
    }
    
    .flood-warning-banner--3-digits .flood-warning-count__count {
        font-size: to-rem(34px);
    }
}

@include tablet {

    .flood-warning-count {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .flood-warning-count__left {
        margin-bottom: 0;
    }

    .flood-warning-count__count {
        font-size: to-rem(47px);
        margin-right: 10px;
    }

    .flood-warning-count__icon {
        margin-right: 9px;
    }
}

@media #{$searchBreakpoint} {
    .flood-warning-banner {
        margin-left: 20px;
        margin-top: 0;
    }

    .flood-warning-banner__item {
        width: auto;
        margin-left: 3px;
        flex-grow: 1;
        flex-shrink: 0;

        &:first-child {
            margin-left: 0;
        }
    }

    .flood-warning-count {
        padding-top: 0;
        padding-bottom: 0;
    }

    .flood-warning-count__text {
        max-width: 80px;
        font-size: to-rem(16px);
        color: #333333;
    }

    .flood-warning-count__icon {
        height: 52px;
        width: 52px;
    }

    .flood-warning-count__count--2-digits,
    .flood-warning-count__count--3-digits {
        margin-left: -10px;
    }

    .flood-warning-banner--3-digits .flood-warning-count__count {
        font-size: to-rem(40px);
    }
    
    .flood-warning-count__dropdown-icon {
        bottom: 7px;
        right: 5px;
        width: 16px;
        height: 16px;
    }

    .flood-warning-count-out-of-force-desktop__active {
        display: block;
    }

    .flood-warning-count-out-of-force-mobile__active {
        display: block;
    }

    .flood-warning-count-out-of-force-mobile {
        display: none;
    }

    .flood-warning-count--open {    
        &:after {
            height: 5px;
        }

        &:before {
            top: -6px;
        }
    }

    .flood-warning-banner__out-of-force-count-dropdown.flood-warning-count-dropdown--open {
        display: block;
    }

    .flood-warning-banner__count-dropdown {
        top: calc(100% + 3px);
    }

    .flood-warning-count-dropdown__severity {
        padding-bottom: 15px;
        padding-top: 20px;
        font-size: to-rem(18px);
        line-height: (19 / 18);
    }

    .flood-warning-count-dropdown__target-area-list-item-link {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}