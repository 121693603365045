﻿.view-container--combined-view {

    .view-container__list {
        overflow: hidden;

        .constrain {
            height: 100%;
        }
    }

    .live-message-list__items {
        overflow-y: auto;
        position: absolute; // this has been made absolute so that the bottom matches up exactly with the map next to it. different zoom levels, resolutions and browsers can mean specific heights don't necessarily match
        top: 76px;
        bottom: 0;
        left: 0;
        right: 0;
    }
}


.landing-page {
    .tabs {
        display: none;

        .tab[data-view="combinedView"] {
            display: none;
        }
    }

    @media screen and (max-width: #{$desktopWidth + 19}) {
        .tabs {
            display: block;
        }
    }
}

// Search on mobile is not updating list view.
// The map container is set to display:none therefore the map is not "zoomed" and the events to update the map are not triggered.
// This is a dirtly little CSS fix to show the map but position it off the screen.
.view-container {
    &.view-container--list-view {
        .view-container__map {
            display: block;
            position: absolute;
            left: -5000px;
            width: 100%;
        }
    }
}
