﻿.leaflet-div-icon {
    background: none;
    border: none;
}

.leaflet-bar button.easy-button-button.disabled {
    cursor: default;
    background-color: #f4f4f4;
    color: #bbb;

    img {
        opacity: 0.4;
    }
}