﻿@keyframes border-pulsate {
    0% {
        border-color: rgba(0,0,0,1);
    }

    50% {
        border-color: rgba(0,0,0,0);
    }

    100% {
        border-color: rgba(0,0,0,1);
    }
}

.symbol-icon-selected {
    animation: border-pulsate 2s infinite;
    border: 3px solid black;
    border-radius: 5px;
}

// Cluster Symbol Icon
.cluster-icon-container {
    &.symbol-icon-selected {
        width: 46px !important;
        height: 46px !important;
    }
}

// Warning/Alert Symbol Icon
.leaflet-marker-icon {
    &.symbol-icon-selected {
        height: 42px !important;
        width: 42px !important;
    }
}