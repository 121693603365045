﻿.modal-container {
    &__modal {
        max-width: 764px;
    }

    button {
        font-weight: 500;
        border: none;
    }

    .share-data-modal {
        max-width: 400px;
        
        &__social-media-icons {
            a {
                height: 50px;
                width: 50px;
                border-radius: 50%;
            }
            .share-data-modal {
                &__social-media-link {
                    &--whatsapp {
                        background: url('/images/socialmedia/whatsapp-logo.svg') $lightgrey center center no-repeat;
                        background-size: 26px;
                    }
                }
            }
        }
    }
}